import React from "react";

function ThirdInstructionalScreen() {
  return (
    <div className="first__screen_instruction_wrap">
      <div className="content__Box">
        <h3>Solve the Problem!</h3>
        <div className="instruction__sub_title">
          However, when you add some liquids or combinations of liquids, the
          beaker will turn <span className="color__red">red</span>. Your task in
          this experiment is to try to make{" "}
          <span className="color__red">red</span>.
        </div>
      </div>
      <div className="img__box">
        <img
          className="red__third_beaker_img"
          src={`${process.env.PUBLIC_URL}/assets/redBeaker.png`}
          alt=""
        />
      </div>
    </div>
  );
}

export default ThirdInstructionalScreen;
