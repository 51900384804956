import React, { useState } from "react";
import IKnowComponent from "./IKnowComponent";
import ChemMatchingGamePhaseT from "./ChemMatchingGamePhaseT";

const FormPage = () => {
  const [showIKnowComponent, setShowIKnowComponent] = useState(false);

  const handleBack = () => {
    window.location.href = "/";
  };

  const handleIKnowClick = () => {
    setShowIKnowComponent(true);
  };

  if (showIKnowComponent) {
    return <IKnowComponent />;
  }
  return (
    <>
      <div className="form__page_wrapper">
        <header>
          <h1>LOGO</h1>
        </header>
        <div className="first__screen_instruction_wrap">
          <div className="content__Box">
            <h3>The Second {"(and final)"} Part of the Problem!</h3>
            <div className="instruction__sub_title">
              Now that you know which liquids combine to make red, experiment
              with the other liquids to see what effects they create:
            </div>
            <ul className="form__page_content_list">
              <li>
                Mix different combinations of liquids by pouring them into the
                beaker.
              </li>
              <li>
                When you believe you understand what each liquid does and how
                the liquids interact, click the "I KNOW" button.
              </li>
            </ul>
          </div>
          <div className="img__box ChemMatchingGame">
            {/* <img src={`${process.env.PUBLIC_URL}/assets/flaskSet.png`} alt="" />
            <img src={`${process.env.PUBLIC_URL}/assets/fsBeaker.png`} alt="" /> */}
            <ChemMatchingGamePhaseT />
          </div>
        </div>
        <footer>
          <div className="left__footer_col">
            {/* <button className="back__btn_footer" onClick={handleBack}>
              <img src={`${process.env.PUBLIC_URL}/assets/arrow.png`} alt="" />
              Back
            </button> */}
          </div>
          <div className="right__footer_col">
            {/* <button className="giveup_button_form_page" onClick={handleBack}>
              Give Up
            </button> */}
            <button className="next__btn_footer" onClick={handleIKnowClick}>
              I Know
            </button>
          </div>
        </footer>
      </div>
    </>
  );
};

export default FormPage;
