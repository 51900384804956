import React from "react";
import BeforeGameInstructionalPopup from "./BeforeGameInstructionalPopup";

function FourthInstructionalScreen() {
  return (
    <div className="first__screen_instruction_wrap fourth__screen_content">
      <div className="content__Box">
        <h3>How to Solve the Problem!</h3>
        <div className="instruction__sub_title">
          Now it is time to figure out how to make the liquid in the large
          beaker turn red. Try pouring different liquids from one or more of the
          small glasses into the large beaker. You can try the liquids one at a
          time and you can try combinations of liquids.
        </div>
        <div className="instruction__body_copy">
          <div className="list__instruction_content">
            <p className="title__group">Here's how to mix the liquids: </p>
            <ul>
              <li>Click on the small glass or glasses you want to use.</li>
              <li>
                Click the "Pour" button to transfer the liquid into the large
                beaker.
              </li>
              <li>
                If the liquid in the large beaker turns red, you've done it
                right!
              </li>
              <li>
                If the color isn't red, click the "Empty" button to clear the
                beaker and try again with the same or different liquids.
              </li>
            </ul>
            <p className="goodluck__content">
              If you find the task too challenging, it's okay! You can click the
              "Give Up" button. But make sure to give it a good try before you
              do. <span>Good luck!</span>
            </p>
          </div>
        </div>
      </div>
      <div className="img__box">
        <img src={`${process.env.PUBLIC_URL}/assets/flaskSet.png`} alt="" />
        <img src={`${process.env.PUBLIC_URL}/assets/fsBeaker.png`} alt="" />
      </div>
    </div>
  );
}

export default FourthInstructionalScreen;
