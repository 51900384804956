import React from "react";

function SecondInstructionalScreen() {
  return (
    <div className="first__screen_instruction_wrap">
      <div className="content__Box">
        <h3>Solve the Problem!</h3>
        <div className="instruction__sub_title">
          Normally when you add liquid to the beaker, the liquid turns a{" "}
          <span className="color__skyBlue">light blue.</span>
        </div>
      </div>
      <div className="img__box">
        <img
          className="light_blue_beaker_img"
          src={`${process.env.PUBLIC_URL}/assets/lightBlue.png`}
          alt=""
        />
      </div>
    </div>
  );
}

export default SecondInstructionalScreen;
