import React, { useState } from "react";
import ChemMatchingGame from "./ChemMatchingGame";

const GameScreenPlay = ({ setGameGlobalReset, gameGlobalReset }) => {
  return (
    <div className="first__screen_instruction_wrap">
      <div className="content__Box">
        <h3>The Problem!</h3>
        <div className="instruction__sub_title">
          Okay! Start trying to make red by clicking on one or more of the
          glasses and then hitting the “Pour” button.  Remember to try again,
          you first need to click on the “Empty” button
        </div>
      </div>
      <ChemMatchingGame
        setGameGlobalReset={setGameGlobalReset}
        gameGlobalReset={gameGlobalReset}
      />
    </div>
  );
};
export default GameScreenPlay;
