import React from "react";

function FirstInstructionalScreen() {
  return (
    <div className="first__screen_instruction_wrap">
      <div className="content__Box">
        <h3>Solve the Problem!</h3>
        <div className="instruction__sub_title">
          And now for something completely different! Here we are interested in
          how you think. So we would like you to solve a problem.
        </div>
        <div className="instruction__body_copy">
          Below you can see four small bottles of liquid. They are numbered 1,
          2, 3, and 4. There is also one large beaker. The large beaker is lined
          with a chemical.
        </div>
      </div>
      <div className="img__box">
        <img
          className="img_info_first"
          src={`${process.env.PUBLIC_URL}/assets/flaskSet.png`}
          alt=""
        />
        <img
          className="img_info_second"
          src={`${process.env.PUBLIC_URL}/assets/fsBeaker.png`}
          alt=""
        />
      </div>
    </div>
  );
}

export default FirstInstructionalScreen;
